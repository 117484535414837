<template>
    <div>
        <div class="row justify-content-center" v-if="prints">
          <div class="col-md-10">
                <div v-if="datanya" class="rounded-lg shadow p-2">
                    <table class="table table-sm table-bordered table-striped">
                         <tr>        
                            <td class="capitalize">jumlah barcode</td>
                            <td class="pl-2 pr-2">:</td>
                            <td>
                                <div class="sm-form ">
                                    <input type="number" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="databarcode.jumlah" >
                                </div>
                            </td>
                        </tr>
                         <tr>        
                            <td class="capitalize">lebar garis barcode</td>
                            <td class="pl-2 pr-2">:</td>
                            <td>
                                <div class="sm-form ">
                                    <input type="number" id="lebar" name="lebar" class="form-control form-control-sm" placeholder="lebar" v-model="databarcode.lebar" >
                                </div>
                            </td>
                        </tr>
                         <tr>        
                            <td class="capitalize">tinggi garis barcode</td>
                            <td class="pl-2 pr-2">:</td>
                            <td>
                                <div class="sm-form ">
                                    <input type="number" id="tinggi" name="tinggi" class="form-control form-control-sm" placeholder="tinggi" v-model="databarcode.tinggi" >
                                </div>
                            </td>
                        </tr>
                         <tr>        
                            <td class="capitalize">warna garis barcode</td>
                            <td class="pl-2 pr-2">:</td>
                            <td>
                                <div class="sm-form ">
                                    <input type="color" id="warna" name="warna" class="form-control form-control-sm" placeholder="warna" v-model="databarcode.warna" >
                                </div>
                            </td>
                        </tr>
                    </table>
                     <div class="sm-form ">
                        <input type="number" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah barcode " v-model="databarcode.jumlah" >
                    </div>
                    <table class="table table-sm table-striped table-bordered">
                        <tr>
                            <th>Barcode</th>
                            <th>Gambar</th>
                            <th>Nama Produk</th>
                            <th>Aksi</th>
                        </tr>
                        <tr v-for="(item, index) in td" :key="index">
                            <td>
                                 <svg :id="`barcode${index}`"></svg>
                            </td>
                            <td>
                            <img v-viewer :src="item.gambar1" style="width:40px;height:40px;" alt="">
                            </td>
                            <td class="font-semibold">
                            <p class="text-xs">{{item.nama_produk}}</p>
                            </td>
                            <td>
                            <button type="button" @click="printbarcode(item)" class="btn btn-sm btn-primary  ">Print barcode</button>
                            </td>
                        </tr>
                    </table>
                    </div>
                <hr>
          </div>
        </div>
        <div v-else>
            <div>
                <button type="button" @click="printini" class="btn btn-sm btn-primary  "><span class="typcn typcn-printer"></span> Print</button>
            </div>
            <svg id="barcodesemua" v-for="(item, index) in parseInt(databarcode.jumlah)" :key="index+'barcode'" class="d-inline"></svg>
        </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>

    </div>
</template>
<script>
import JsBarcode from 'jsbarcode'
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
export default{
    data(){
        return{
            prints:true,
            databarcode:{
                jumlah:10,
                warna: "#000",
                lebar:2,
                tinggi:100,
            },
            datanya:[],
            search:''
        }
    },
    computed:{
        td(){
            let that=this;
            let data =this.datanya;
            // data=data.filter(e=>{
            //     if(e.nama_produk.toLowerCase().indexOf(that.search.toLowerCase())!=-1 || e.barcode.toLowerCase().indexOf(that.search.toLowerCase())!=-1 ){
            //         return e
            //     }
            // })
            return data
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        printini(){
            window.print();
        },
        getData(){
            sdb.collection("app_kasir_produk").doc().get("*","order by id").then(res => {
                console.log(res);
                this.datanya=res;
                setTimeout(() => {
                    this.datanya.forEach((e,i)=>{
                       JsBarcode(`#barcode${i}`, e.barcode);
                    })
                    JsBarcode(`.barcode-semua`, 'abcd');
                }, 2000);
            });
            
        },
        printbarcode(item){
            let that=this
            sdb.alert('print barcode')
            sdb.loadingOn('print barcode')
            setTimeout(() => {
                this.prints=false
                setTimeout(() => {
                    JsBarcode(`#barcodesemua`, item.barcode, {
                        lineColor: that.databarcode.warna,
                        width:that.databarcode.lebar,
                        height:that.databarcode.tinggi,
                    });
                }, 2000);
                sdb.loadingOff('print barcode')
            }, 2000);
        }
    },
}
</script>
<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print * {
    display:initial !important;
  }
}
</style>